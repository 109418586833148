<template>
  <div class="share-page">
    <div class="defalut-title">
      <img src="../../assets/images/share/title.png" alt="" class="img-title" />
      <img
        src="../../assets/images/share/big-star.png"
        alt=""
        class="star big-star"
      />
      <img
        src="../../assets/images/share/small-star.png"
        alt=""
        class="star star-left"
      />
      <img
        src="../../assets/images/share/small-star.png"
        alt=""
        class="star star-right"
      />
    </div>
    <div class="share-moment">
      <div class="transparent-bg"></div>
      <div class="share-moment-main">
        <div class="dot"></div>
        <div class="img-swiper" v-if="displayFiles.length > 0">
          <swiper
            class="swiper"
            :modules="[Pagination]"
            :pagination="{ clickable: true }"
            :options="swiperOptions"
          >
            <swiper-slide
              v-for="(item, index) in displayFiles"
              :key="index"
              class="swiperItem cursor-p"
            >
              <img
                :src="item.dataUrlManage"
                alt=""
                @error="handleImageError(index)"
                @click="previewImages(index)"
              />
            </swiper-slide>
          </swiper>
        </div>
        <div
          :class="displayFiles.length > 0 ? 'flex m-h42' : 'flex m-h56'"
          style="justify-content: space-between; flex-direction: column"
        >
          <div
            :class="
              displayFiles.length > 0
                ? 'share-content'
                : 'share-content not-translate'
            "
          >
            <div class="flex" style="justify-content: flex-start">
              <a-avatar
                :src="!userInfo.avatar ? avatarLogo : userInfo.avatar"
                :size="54"
              />
              <span class="ml-10 mt-20 fs-14 fw-6">{{
                !userInfo.userName ? '匿名用户' : userInfo.userName
              }}</span>
            </div>
            <div class="dis fs-15">
              <img
                src="../../assets/images/share/yinhao.png"
                alt=""
                class="icon-yinhao"
              />
              <img
                src="../../assets/images/share/yinhao.png"
                alt=""
                class="icon-yinhao icon-bottom"
              />
              <!--          <p v-html="recordInfo.momentContent"></p>-->
              <p class="mb-15" style="position: relative; z-index: 2">
                {{ recordInfo.momentTitle }}
              </p>
              <div v-for="(item, index) in momentContentArray" :key="index">
                <div style="position: relative; z-index: 2">{{ item }}</div>
              </div>
            </div>
          </div>

          <div class="share-bottom">
            <div class="backlog-file-list" v-if="filesInfo?.length > 0">
              <template v-for="fileItem in filesInfo" :key="fileItem.id">
                <a
                  v-if="commonTypes.includes(fileItem.suffix)"
                  :href="fileItem.dataUrlCustom"
                  :download="fileItem.originalFileName"
                  style="color: #3d3d3d; width: 100%"
                >
                  <div class="backlog-file-item">
                    <img
                      class="backlog-file-icon"
                      src="@/assets/images/timeline/zip-icon.png"
                      alt=""
                      v-if="fileItem.suffix.toLowerCase() == 'zip'"
                    />
                    <img
                      class="backlog-file-icon"
                      src="@/assets/images/timeline/doc-icon.png"
                      alt=""
                      v-else-if="
                        fileItem.suffix.toLowerCase() == 'doc' ||
                        fileItem.suffix.toLowerCase() == 'docx'
                      "
                    />
                    <img
                      class="backlog-file-icon"
                      src="@/assets/images/timeline/mp3-icon.png"
                      alt=""
                      v-else-if="fileItem.suffix.toLowerCase() == 'mp3'"
                    />
                    <img
                      class="backlog-file-icon"
                      src="@/assets/images/timeline/mp4-icon.png"
                      alt=""
                      v-else-if="
                        fileItem.suffix.toLowerCase() == 'mp4' ||
                        fileItem.suffix.toLowerCase() == 'mov'
                      "
                    />
                    <img
                      class="backlog-file-icon"
                      src="@/assets/images/timeline/pdf-icon.png"
                      alt=""
                      v-else-if="fileItem.suffix.toLowerCase() == 'pdf'"
                    />
                    <img
                      class="backlog-file-icon"
                      src="@/assets/images/timeline/ppt-icon.png"
                      alt=""
                      v-else-if="
                        fileItem.suffix.toLowerCase() == 'ppt' ||
                        fileItem.suffix.toLowerCase() == 'pptx'
                      "
                    />
                    <img
                      class="backlog-file-icon"
                      src="@/assets/images/timeline/xlsx-icon.png"
                      alt=""
                      v-else-if="fileItem.suffix.toLowerCase() == 'xlsx'"
                    />
                    <img
                      class="backlog-file-icon"
                      src="@/assets/images/timeline/file-icon.png"
                      alt=""
                      v-else
                    />
                    <div class="backlog-file-message">
                      <span class="backlog-file-title show2Row">{{
                        fileItem.originalFileName
                      }}</span>
                      <span class="backlog-file-size">{{
                        (fileItem.size / 1024 / 1024).toFixed(2) + 'MB'
                      }}</span>
                    </div>
                    <!-- <div class="icon-download" >
                   
                      <img src="@/assets/images/share/icon-down.png" alt=""
                    />
                  </div> -->
                  </div>
                </a>
                <a
                  v-else
                  @click.prevent="tipMsgToBrowser"
                  style="color: #3d3d3d; width: 100%"
                >
                  <div class="backlog-file-item">
                    <img
                      class="backlog-file-icon"
                      src="@/assets/images/timeline/zip-icon.png"
                      alt=""
                      v-if="fileItem.suffix.toLowerCase() == 'zip'"
                    />
                    <img
                      class="backlog-file-icon"
                      src="@/assets/images/timeline/doc-icon.png"
                      alt=""
                      v-else-if="
                        fileItem.suffix.toLowerCase() == 'doc' ||
                        fileItem.suffix.toLowerCase() == 'docx'
                      "
                    />
                    <img
                      class="backlog-file-icon"
                      src="@/assets/images/timeline/mp3-icon.png"
                      alt=""
                      v-else-if="fileItem.suffix.toLowerCase() == 'mp3'"
                    />
                    <img
                      class="backlog-file-icon"
                      src="@/assets/images/timeline/mp4-icon.png"
                      alt=""
                      v-else-if="
                        fileItem.suffix.toLowerCase() == 'mp4' ||
                        fileItem.suffix.toLowerCase() == 'mov'
                      "
                    />
                    <img
                      class="backlog-file-icon"
                      src="@/assets/images/timeline/pdf-icon.png"
                      alt=""
                      v-else-if="fileItem.suffix.toLowerCase() == 'pdf'"
                    />
                    <img
                      class="backlog-file-icon"
                      src="@/assets/images/timeline/ppt-icon.png"
                      alt=""
                      v-else-if="
                        fileItem.suffix.toLowerCase() == 'ppt' ||
                        fileItem.suffix.toLowerCase() == 'pptx'
                      "
                    />
                    <img
                      class="backlog-file-icon"
                      src="@/assets/images/timeline/xlsx-icon.png"
                      alt=""
                      v-else-if="fileItem.suffix.toLowerCase() == 'xlsx'"
                    />
                    <img
                      class="backlog-file-icon"
                      src="@/assets/images/timeline/file-icon.png"
                      alt=""
                      v-else
                    />
                    <div class="backlog-file-message">
                      <span class="backlog-file-title show2Row">{{
                        fileItem.originalFileName
                      }}</span>
                      <span class="backlog-file-size">{{
                        (fileItem.size / 1024 / 1024).toFixed(2) + 'MB'
                      }}</span>
                    </div>
                    <!-- <div class="icon-download" >
                   
                      <img src="@/assets/images/share/icon-down.png" alt=""
                    />
                  </div> -->
                  </div>
                </a>
              </template>
            </div>
            <div
              class="flex contact-list"
              v-if="isShow && contactInfo.length > 0"
            >
              <div
                class="item-avatar-mini mr-6 mb-10 fs-12"
                v-for="(pItem, index) in contactInfo.slice(0, 45)"
                :key="index"
              >
                {{ pItem.name?.charAt(0) }}
              </div>
            </div>
            <div
              class="flex pt-10"
              style="
                border-top: 1px dashed #d1d1d1;
                justify-content: space-between;
              "
            >
              <img
                src="../../assets/images/share/logo.png"
                alt=""
                srcset=""
                class="bottom-img"
              />
              <!-- <img src="../../assets/images/share/logo-qrcode.png" alt="" srcset="" class="bottom-img"> -->
              <div class="qr-img">
                <vue-qr
                  :text="handleInvitLine(userInfo.invitationCode)"
                  :margin="0"
                  :size="56"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <van-image-preview
    v-model:show="show"
    :loop="false"
    :images="fileImagesInfo.map(item => item.dataUrlCustom)"
    :startPosition="index"
    @change="onChange"
  >
  </van-image-preview>

</template>

<script>
import { reactive, toRefs, ref, onMounted, watch } from 'vue';
import { Swiper, SwiperSlide } from 'swiper/swiper-vue.mjs';
import { Pagination } from 'swiper/modules';
import { Avatar, message } from 'ant-design-vue';
import { useRoute } from 'vue-router';
import 'swiper/swiper.css';
import 'swiper/swiper-bundle.css';
import shareApi from '@/api/share';
import avatarLogo from '@/assets/images/share/avatar-logo.png';
import logoImg from '@/assets/images/share/logo-qrcode.png';
import VueQr from 'vue-qr/src';
// import VideoModal from './components/VideoModal.vue';
export default {
  components: {
    Swiper,
    SwiperSlide,
    AAvatar: Avatar,
    VueQr,
    // VideoModal
  },
  setup() {
    const commonTypes = [
      'txt',
      'pdf',
      'mov',
      'mp4',
      'mp3',
      'word',
      'excel',
      'ppt',
      'pptx',
      'doc',
      'docx',
      'xlsx',
      'html',
    ]; // 常见可直接下载的类型
    const route = useRoute();
    // const router = useRouter();
    const downloadLink = ref(null);
    const recordId = route.query.id;
    const isShow = ref(true);
    const isModalVisible = ref(true);
    const show = ref(false);
    const index = ref(0);
    const states = reactive({
      swiperOptions: {
        loop: false,
        slidesPerView: 1,
        spaceBetween: 10,
      },
      recordInfo: {},
      userInfo: {},
      fileImagesInfo: [],
      filesInfo: [],
      displayFiles: [],
      contactInfo: [],
      momentContentArray: [],
      logoImg,
      currentVideoSrc: '',
    });
    const handleInvitLine = invitationCode => {
      let line =
        process.env.VUE_APP_SERVER_LOGIN +
        `inviteLink?inviterCode=${invitationCode}`;
      console.log(line, 99);
      return line;
    };
    const tipMsgToBrowser = () => {
      message.warn('可在浏览器打开此网页下载文件');
    };
    const onChange = newIndex => {
      index.value = newIndex;
    };
    // const  openVideo=(src)=> {
    //   console.log(src,444)
    //  states.currentVideoSrc = src;
    //  isModalVisible.value = true;
    // };
    onMounted(() => {
      shareApi.getShareRecords({ recordId }).then(res => {
        if (res) {
          isShow.value == res.isRelatedPersons;
          states.contactInfo = res.contactVOList || [];
          states.fileImagesInfo =
            res.filesRecordsList.filter(item =>
              ['jpg', 'jpeg', 'png', 'svg', 'gif', 'image'].includes(
                item.suffix
              )
            ) || [];
          states.filesInfo =
            res.filesRecordsList.filter(
              item =>
                !['jpg', 'jpeg', 'png', 'svg', 'gif', 'image'].includes(
                  item.suffix
                )
            ) || [];
          states.userInfo = res.sysUser || {};
          states.recordInfo = res.contactTodoMoment || {};
          states.displayFiles = [...states.fileImagesInfo];
          states.momentContentArray =
            states.recordInfo.momentContent.split('\n');
        }
      });
    });

    return {
      ...toRefs(states),
      Pagination,
      isShow,
      isModalVisible,
      show,
      index,
      vedioSrc: '',
      commonTypes,
      onChange,
      avatarLogo,
      tipMsgToBrowser,
      handleInvitLine,
      handleImageError(index) {
        states.displayFiles.splice(index, 1);
      },
      handleContent() {
        states.recordInfo.momentContent.split('\n');
      },
      previewImages(i) {
        show.value = true;
        index.value = i;
      },
    };
  },
};
</script>

<style lang="less" scoped>
.share-page {
  background: linear-gradient(339deg, #ffd167 0%, #ff922c 100%);
  box-shadow: 0px 4px 8px 1px rgba(145, 70, 8, 0.24);
  min-height: 100vh;
  padding: 36px 16px 64px 16px;
  .defalut-title {
    position: relative;
    text-align: center;
    margin-bottom: 7.9vw;
    .sub-title {
      font-weight: 800;
      font-size: 16px;
      color: #000000;
      margin-bottom: 18px;
    }
    .img-title {
      width: 59%;
      margin: 0 auto;
      object-fit: contain;
    }
    .star {
      position: absolute;
      width: 2.5vw;
      height: 2.5vw;
    }
    .big-star {
      width: 6.1vw;
      height: 6.1vw;
      left: 12%;
      top: -50%;
    }
    .star-left {
      left: 6%;
      bottom: -8%;
    }
    .star-right {
      right: 16%;
      bottom: -32%;
    }
  }
  .share-moment {
    position: relative;
  }
  .share-moment-main {
    min-height: 69vh;
    background: linear-gradient(179deg, #fff1d9 0%, #ffffff 100%);
    border-radius: 18px;
    border: 4px solid #ffffff;
    position: relative;
    z-index: 1;
    padding: 3.8vw 3vw;
  }
}
.transparent-bg {
  width: 96%;
  left: 2%;
  position: absolute;
  height: 97%;
  background: rgba(255, 255, 255, 0.5);
  border-radius: 18px;
  transform: rotateZ(-2deg);
  z-index: 0;
}
.img-swiper {
  width: 100%;
  position: relative;
  z-index: 3;
  border-radius: 8px;
  img {
    width: 100%;
    height: 52vw;
    border-radius: 8px;
    object-fit: cover;
  }
}
.contact-list {
  padding: 0 0 10px 0;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.share-content {
  width: 100%;
  position: relative;
  padding: 0px 10px;
  transform: translateY(-5vw);

  z-index: 9;
  .dis {
    margin: 6vw auto 3vw;
    position: relative;
    min-height: 54px;
    padding: 10px;
  }
  .icon-yinhao {
    position: absolute;
    width: 32px;
    height: 32px;
    left: 0;
    top: 0;
  }
  .icon-bottom {
    left: initial;
    top: initial;
    right: 0;
    bottom: 0;
    transform: rotateZ(180deg);
  }
}
.not-translate {
  transform: translateY(0);
}
.share-bottom {
  width: 100%;
}
.item-avatar-mini {
  width: 24px;
  height: 24px;
  background-color: #ff7b00;
  color: #fff;
  line-height: 24px;
  text-align: center;
  border-radius: 50%;
}
.bottom-img {
  width: 50vw;
  height: 15.6vw;
  object-fit: contain;
}
.qr-img {
  border-radius: 4px;
  padding: 2px;
  border: 2px solid #000;
}

.backlog-file-list {
  width: 100%;
  margin-top: 36px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;

  .backlog-file-item {
    width: 100%;
    padding: 14px 12px;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 8px;
    background: #ffb060;
    color: #fff;
    position: relative;

    .backlog-file-icon {
      flex: 0 0 4vw;

      height: 7.4vw;
      object-fit: cover;
    }

    .backlog-file-message {
      margin-left: 8px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 4px;
      flex: 1;

      .backlog-file-title {
        font-size: 15px;
        font-weight: 400;
        line-height: 1;
        word-break: break-all;
        width: 97%;
      }

      .backlog-file-size {
        font-size: 14px;
        font-weight: 400;
        line-height: 1;
      }
    }

    .menu-btn {
      width: 24px;
      height: 24px;
      margin-left: auto;
      object-fit: cover;
      opacity: 0;
      transition: opacity 0.3s;
      cursor: pointer;
    }

    &:hover {
      .menu-btn {
        opacity: 1;
        transition: opacity 0.3s;
      }
    }
  }
}
.show2Row {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  white-space: normal !important;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
:deep(.swiper-pagination-bullet-active) {
  background: #ffffff;
}
.m-h56 {
  min-height: 62vh;
}
.m-h42 {
  min-height: 42vh;
}
.dot {
  position: absolute;
  top: 1.8vw;
  right: 1.8vw;
  width: 16.75px;
  height: 16.75px;
  border-radius: 50%;
  background: #ff9a34;
  border: 2px solid #ffffff;
  z-index: 2;
}
// .icon-download {
//   width: 5.3vw;
//   height: 5.3vw;
//   position: absolute;
//   right: 4vw;
//   top: 50%;
//   transform: translateY(-50%);
//   img {
//     width: 100%;
//     height: 100%;
//   }
// }

:deep(.ant-avatar-image) {
  border: 2px solid #ffffff;
}
:deep(.swiper) {
  border-radius: 8px;
}
:deep(.swiper-wrapper) {
  border-radius: 8px;
  .swiper-slide {
    border-radius: 8px;
  }
}
</style>
