


'use strict';

import http from '@/utils/http';
// 查询单个时刻(对外)
export default {
    getShareRecords(params) {
    return http.get(
      '/connections_treasure_box/contact/moment-share-records/getShareRecordsById?id=' +
        params.recordId
    );
  },
};
